import request, { MarkcoinRequest } from '@/plugins/request'
import { IOrder } from '@/typings/api/lin'
import {
  YapiGetV1ChainStarGetOpenStationInitApiRequest,
  YapiGetV1ChainStarGetOpenStationInitApiResponse,
} from '@/typings/yapi/ChainStarGetOpenStationInitV1GetApi'
import { YapiPostOrderCreateOrderApiRequest } from '@/typings/yapi/OrderCreateOrderPostApi'
import { YapiGetOrderDetailApiRequest, YapiGetOrderDetailApiResponse } from '@/typings/yapi/OrderDetailGetApi'
import {
  YapiGetOrderGetOrderStatusApiRequest,
  YapiGetOrderGetOrderStatusApiResponse,
} from '@/typings/yapi/OrderGetOrderStatusGetApi'
import { YapiGetOrderListApiRequest, YapiGetOrderListApiResponse } from '@/typings/yapi/OrderListGetApi'
import { YapiPostOrderOpenSiteApiRequest, YapiPostOrderOpenSiteApiResponse } from '@/typings/yapi/OrderOpenSitePostApi'

/**
 * [创建订单↗](https://yapi.nbttfc365.com/project/85/interface/api/11844)
 * */
export const postOrderCreateOrderApiRequest: MarkcoinRequest<YapiPostOrderCreateOrderApiRequest, IOrder> = data => {
  return request({
    path: '/order/createOrder',
    method: 'POST',
    data,
  })
}

/**
 * [获取订单列表↗](https://yapi.nbttfc365.com/project/85/interface/api/11309)
 * */
export const getOrderListApiRequest: MarkcoinRequest<
  YapiGetOrderListApiRequest,
  YapiGetOrderListApiResponse['data']
> = params => {
  return request({
    path: '/order/list',
    method: 'GET',
    params,
  })
}

/**
 * [获取一个订单详情↗](https://yapi.nbttfc365.com/project/85/interface/api/11314)
 * */
export const getOrderDetailApiRequest: MarkcoinRequest<
  YapiGetOrderDetailApiRequest,
  YapiGetOrderDetailApiResponse['data']
> = params => {
  return request({
    path: '/order/detail',
    method: 'GET',
    params,
  })
}

/**
 * [获取订单状态↗](https://yapi.nbttfc365.com/project/85/interface/api/11909)
 * */
export const getOrderGetOrderStatusApiRequest: MarkcoinRequest<
  YapiGetOrderGetOrderStatusApiRequest,
  YapiGetOrderGetOrderStatusApiResponse['data']
> = params => {
  return request({
    path: '/order/getOrderStatus',
    method: 'GET',
    params,
  })
}

/**
 * [获取开站价格币种信息↗](https://yapi.nbttfc365.com/project/85/interface/api/11979)
 * */
export const getV1ChainStarGetOpenStationInitApiRequest: MarkcoinRequest<
  YapiGetV1ChainStarGetOpenStationInitApiRequest,
  YapiGetV1ChainStarGetOpenStationInitApiResponse['data']
> = params => {
  return request({
    path: '/v1/chainStar/getOpenStationInit',
    method: 'GET',
    params,
  })
}

/**
 * [开站倒计时通知↗](https://yapi.nbttfc365.com/project/85/interface/api/12114)
 * */
export const postOrderOpenSiteApiRequest: MarkcoinRequest<
  YapiPostOrderOpenSiteApiRequest,
  YapiPostOrderOpenSiteApiResponse['data']
> = data => {
  return request({
    path: '/order/openSite',
    method: 'POST',
    data,
  })
}
